<template>
  <div class="!bg-blue-900">
    <header-home></header-home>
    <section-news id="news" :posts="posts" class="mb-14 xl:mb-20"></section-news>
    <section-page-preview
      id="information"
      :title="t('information.title')"
      :content="t('information.content')"
      :categories="information.items"
      :link="localePath('information')"
      title-color="text-black"
      border-color="border-green"
      background-color="bg-green"
      background-light-color="bg-green-900"
      class="pb-16 xl:pb-20"
    ></section-page-preview>

    <section-page-preview
      id="prevention"
      :title="t('prevention.title')"
      :content="t('prevention.content')"
      :categories="prevention.items"
      :link="localePath('prevention')"
      title-color="text-black"
      border-color="border-orange"
      background-color="bg-orange"
      background-light-color="bg-orange-900"
      class="pb-16 xl:pb-20"
    ></section-page-preview>

    <section-page-preview
      id="quit"
      :title="t('quit.title')"
      :content="t('quit.content')"
      :categories="quit.items.filter((item) => item.id !== 405)"
      title-color="text-white"
      :link="localePath('quit')"
      border-color="border-dark-blue"
      background-color="bg-dark-blue"
      background-light-color="bg-dark-blue-900"
      class="pb-20 xl:pb-20"
    >
      <template #append>
      </template>
    </section-page-preview>
  </div>
</template>

<script>
import CardQuitCtaHome from "~/components/Cards/CardQuitCtaHome.vue";
import HeaderHome from "~/components/Headers/HeaderHome.vue";
import SectionNews from "~/components/Sections/SectionNews.vue";
import SectionPagePreview from "~/components/Sections/SectionPagePreview.vue";


import { setupMeta } from "~/utils/utils";

export default {
  name: "PageHome",
  async setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    const nuxtApp = useNuxtApp();

    const { information, prevention, quit, latest_posts } = nuxtApp.$store.state.web;

    defineI18nRoute({
      locales: ["fr"],
      paths: {
        fr: "/",
      },
    });

    if (locale.value == "en") {
      navigateTo("/iquitnow")
    }
    await nuxtApp.runWithContext(() => setupMeta( null, information.seo));

    return {
      t,
      locale,
      localePath,
      information,
      prevention,
      quit,
      posts: latest_posts,
    };
  },
  components: {
    HeaderHome,
    SectionNews,
    SectionPagePreview,
    CardQuitCtaHome,
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "information": {
      "title": "Je m'informe",
      "content": "Cette section offre des informations pertinentes, fiables et des réponses claires sur l’usage du tabac et des produits de vapotage au Québec et dans le monde : composition, méfaits, témoignages d’anciens fumeurs ou vapoteurs ou encore dernières nouvelles."
    },
    "prevention": {
      "title": "Je préviens",
      "content": "L’adolescence est une période critique pour l’initiation à la cigarette et aux produits de vapotage. Cette période constitue donc le meilleur moment pour poser des actions concrètes en matière de prévention ou de cessation auprès des jeunes. Professionnels de la santé, de l’éducation ou parents? Découvrez une foule d’outils et de ressources disponibles sur Je préviens pour soutenir vos interventions."
    },
    "quit": {
      "title": "J'arrête",
      "content": "Vous avez envie d'arrêter de fumer ou de vapoter, mais ne savez pas comment faire?\\nJ'ARRÊTE est là pour vous aider!"
    }
  },
  "en": {}
}
</i18n>
